<template>
  <div id="reserveList">
    <Back title="预定列表"></Back>
    <main>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <section class="block-item" v-for="item in list" :key="item.preorderId">
          <div class="block-title van-hairline--bottom">
            <div class="title-left">
              <i></i>预定人：{{ item.tenantDto.tenantName }}
            </div>
            <div
              class="title-right theme-color"
              @click="linkTo(item.preorderId)"
            >
              查看详情
            </div>
          </div>
          <div class="block-content">
            <p>
              <span class="info-color">房源</span
              ><span
                >{{ item.resourceDtos[0].unitName }}-{{
                  item.resourceDtos[0].floorName
                }}-{{ item.resourceDtos[0].resourceName }}</span
              >
            </p>
            <p>
              <span class="info-color">租期</span
              ><span
                >{{ formatterDate(item.effDate, "yyyy-MM-dd") }}~{{
                  formatterDate(item.expDate, "yyyy-MM-dd")
                }}</span
              >
            </p>
            <p>
              <span class="info-color">预定状态</span
              ><span>{{ preorderStatusObj[item.preorderStatus] }}</span>
            </p>
          </div>
        </section>
      </van-list>
    </main>
  </div>
</template>

<script>
import Back from "@/components/back";
import { getReserveList } from "@/api/contract/contract";
import { formatterDate } from "@/utils/utils";
import { preorderStatusObj } from "@/db/contract";
export default {
  components: { Back },
  data() {
    return {
      formatterDate,
      preorderStatusObj,
      params: {
        pageNo: 0,
      },
      list: [],
      loading: false,
      finished: false, //是否结束加载，切换状态后记得开启
    };
  },
  methods: {
    // 【请求】预定合同列表
    getReserveList() {
      let data = this.params;
      getReserveList(data).then((res) => {
        if (res.data) {
          this.list = [...this.list, ...res.data.content];
          this.loading = false;
          if (this.list.length >= res.data.total) {
            this.finished = true;
          }
        } else {
          this.loading = false;
          this.finished = true;
        }
      });
    },

    // 【请求】下拉加载
    onLoad() {
      this.params.pageNo++;
      this.getReserveList();
    },

    // 【监听】跳转预定合同详情
    linkTo(preorderId) {
      this.$router.push({
        path: "/contract/reserveInfo",
        query: { preorderId },
      });
    },
  },
};
</script>


<style lang="scss" scoped>
@import "~@/assets/styles/theme";
#reserveList {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  overflow: auto;
}
// 大盒子边距
.van-list {
  box-sizing: border-box;
  border-left: 0.15rem $bg-color solid;
  border-right: 0.15rem $bg-color solid;
}

// 文本模块
.block-item {
  border-top: $bg-color 0.15rem solid;
  display: flex;
  flex-direction: column;
  padding: 0 0.16rem 0.16rem 0.16rem;
  box-sizing: border-box;
  > .block-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.1rem 0;
    box-sizing: border-box;
    .title-left {
      display: flex;
      align-items: center;
      > i {
        display: inline-block;
        background: $theme-color;
        width: 0.04rem;
        height: 0.15rem;
        border-radius: 0 0.04rem 0.04rem 0;
        margin-right: 0.1rem;
      }
    }
  }
  // 内容区上边距
  .block-content {
    margin-top: 0.1rem;
    > p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.02rem 0;
    }
  }
}
</style>